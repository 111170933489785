.template-1 {
    width: 700px;
}
.template-1 .thick-border {
    border-top: 30px solid #ccc;
}

.template-1 .thick-border.blue {
    border-top: 30px solid #454c9b;
}
.template-1 .bg-blue-header {
    background-color: #454c9b;
    color: #fff;
}
.template-1 .bg-gray-header {
    background-color: #ccc;
}