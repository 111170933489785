.graphbg {
  margin-bottom: 10px;
}
.graph1 {
  float: right;
  position: relative;
  top: 30px;
  z-index: 10;
  clear: both;
  display: inline-block;
  margin-top: -20px;
  right: 10px;
}

.lookgrid {
  margin-top: 0px;
}
.lookgrid a {
  width: 100%;
  display: inline-block;
  padding: 12px 0px;
  /*border-bottom: 1px solid #ddd;*/
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
  color: #333;
  font-size: 13px;
}
.lookgrid a:hover {
  text-decoration: none;
  background: #f9f9f9;
  /*box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);*/
  box-shadow: 0px 2px 5px 0px #dadce0;
  color: #333;
}
.lookgrid .tcolumn1 {
  width: 18%;
  display: inline-block;
  vertical-align: middle;
}
.lookgrid .tcolumn2 {
  width: 14%;
  display: inline-block;
  vertical-align: middle;
}
.lookgrid .tcolumn3 {
  width: 14%;
  display: inline-block;
  vertical-align: middle;
}
.lookgrid .tcolumn4 {
  width: 12%;
  display: inline-block;
  vertical-align: middle;
}
.lookgrid .tcolumn5 {
  width: 10%;
  display: inline-block;
  vertical-align: middle;
}
.lookgrid .tcolumn6 {
  width: 12%;
  display: inline-block;
  vertical-align: middle;
}
.lookgrid .tcolumn7 {
  width: 12%;
  display: inline-block;
  vertical-align: middle;
}
.lookgrid .tcolumn8 {
  width: 3%;
  display: inline-block;
  padding-left: 14px;
  vertical-align: middle;
}
.payablenewtable .dcolumn1 {
  width: 16%;
}
.payablenewtable .dcolumn2 {
  width: 12%;
  /*padding-left: 8px;*/
}
.payablenewtable .dcolumn3 {
  width: 12%;
  padding-left: 8px;
}
.payablenewtable .dcolumn4 {
  width: 12%;
}
.payablenewtable .dcolumn5 {
  width: 12%;
}
.payablenewtable .dcolumn6 {
  width: 12%;
  /*padding-left: 15px;*/
}
.payablenewtable .dcolumn7 {
  width: 12%;
  /*padding-left: 20px;*/
}
.table100.ver1 {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
}
.table100 {
  background-color: #fff;
}
.table100 {
  position: relative;
  padding-top: 60px;
}
.table100-head {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.filtericon {
  width: 12px;
}
table {
  width: 100%;
}
.table100.ver1 th {
  cursor: pointer;
  font-size: 13px;
  color: #fff;
  line-height: 1.4;
  background-color: #2a7fff;
}
.table100-head th {
  padding-top: 16px;
  padding-bottom: 16px;
}
.filterthree {
  padding: 15px;
  margin-bottom: 10px;
  color: #b0b0b0;
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  width: 100%;
}
.filterthree a.lefticon {
  color: #b0b0b0;
  padding: 0px 3px;
}
.filterthree a.righticon {
  color: #000;
  padding: 0px 3px;
}
.default {
  background: #f7f2f2;
  color: #fff;
  border-radius: 50px;
  width: 13px;
  height: 13px;
  display: inline-block;
  margin: 0px 3px -2px 0px;
}
.criticalclr {
  background: #ff0000;
  color: #fff;
  border-radius: 50px;
  width: 13px;
  height: 13px;
  display: inline-block;
  margin: 0px 3px -2px 0px;
}
.lowclr {
  background: #7bca03;
  color: #fff;
  border-radius: 50px;
  width: 13px;
  height: 13px;
  display: inline-block;
  margin: 0px 3px -2px 0px;
}
.highclr {
  background: #f87c02;
  color: #fff;
  border-radius: 50px;
  width: 13px;
  height: 13px;
  display: inline-block;
  margin: 0px 3px -2px 0px;
}
.mediumclr {
  background: #ffb52d;
  color: #fff;
  border-radius: 50px;
  width: 13px;
  height: 13px;
  display: inline-block;
  margin: 0px 3px -2px 0px;
}
.divider {
  border-top: 1px solid #ddd;
  padding: 15px 0px;
  margin-top: 10px;
}

.paynewdetsec label {
  font-weight: 500;
}
.titletranshist {
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 23px;
}

.lookgrid .vtcolumn1 {
  width: 15%;
  display: inline-block;
  vertical-align: middle;
}
.lookgrid .vtcolumn2 {
  width: 15%;
  display: inline-block;
  vertical-align: middle;
}
.lookgrid .vtcolumn3 {
  width: 15%;
  display: inline-block;
  vertical-align: middle;
}
.lookgrid .vtcolumn4 {
  width: 15%;
  display: inline-block;
  vertical-align: middle;
}
.lookgrid .vtcolumn5 {
  width: 15%;
  display: inline-block;
  vertical-align: middle;
}
.lookgrid .vtcolumn6 {
  width: 14%;
  display: inline-block;
  vertical-align: middle;
}

.lookgrid .vtcolumn8 {
  width: 5%;
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
}

.payablenewtable .vdcolumn1 {
  width: 15%;
}
.payablenewtable .vdcolumn2 {
  width: 15%;
  /*padding-left: 8px;*/
}
.payablenewtable .vdcolumn3 {
  width: 15%;
}
.payablenewtable .vdcolumn4 {
  width: 15%;
}
.payablenewtable .vdcolumn5 {
  width: 17%;
}
.payablenewtable .vdcolumn6 {
  width: 20%;
  /*padding-left: 15px;*/
}
.payablenewtable .vdcolumn8 {
  width: 5%;
  /*padding-left: 20px;*/
}
.equaldivider {
  border-top: 1px solid #ddd;
  margin: 30px 0px;
}
.highcharts-exporting-group {
  display: none;
}
.highcharts-credits {
  display: none;
}
.graphdatepicker {
  width: 100%;
  float: left;
  clear: both;
  display: inherit;
  background: #fff;
  padding: 12px 15px 12px 15px;
  margin: 0px 15px 25px 15px;
  border-radius: 0.25rem;
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
}

.form-control .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0 8px;
}

.form-control
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 8px 4px;
  padding-right: 30px;
  font-size: 12px;
}

.form-control .MuiAutocomplete-popupIndicatorOpen {
  transform: none;
}

.expand-arrow {
  width: 20px;
  height: 20px;
  background: #007bff;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.slide-left {
  max-width: 94.666667%;
  padding-right: 20px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  flex: 0 0 94.666667%;
}

.slide-right {
  flex: 0 0 68%;
  max-width: 68%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.display-none {
  display: none;
}

.right-col {
  flex: 0 0 calc(31.5% - 5px);
  max-width: calc(31.5% - 5px);
  /* -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; */
}

.details-accordion .accordion__button[aria-expanded="true"] .fa-chevron-down,
.details-accordion .accordion__button[aria-selected="true"] .fa-chevron-down,
.details-accordion .accordion__button[aria-expanded="false"] .fa-chevron-up,
.details-accordion .accordion__button[aria-selected="false"] .fa-chevron-up {
  display: none;
}
.ccat {
  width: 100%;
  border: 1px solid #fff;
}
.penedit {
  float: right;
  position: absolute;
  right: 7%;
  top: 32px;
}

.notes {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.leftbaricon {
  position: absolute;
  background: #2a7fff;
  color: #fff;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  left: -14px;
  font-size: 12px;
  padding: 4px 0;
  text-align: center;
  top: 15px;
  cursor: pointer;
}

.pulse {
  animation: pulse-animation 7s infinite ease-in-out;
}

@keyframes pulse-animation {
  0%,
  10% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  90%,
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.notes-wrapper {
  max-height: 60vh;
  overflow-y: auto;
}
.cursor-pointer {
  cursor: pointer;
}
.text-small {
  font-size: 10px;
}

.preExpand-0 .accordion_card_1 .card,
.preExpand-1 .accordion_card_2 .card,
.preExpand-2 .accordion_card_3 .card,
.preExpand-3 .accordion_card_4 .card,
.preExpand-4 .accordion_card_5 .card {
  display: flex !important;
}

.preExpand-0 .accordion_card_1 .accordion__button .fa-chevron-down,
.preExpand-1 .accordion_card_2 .accordion__button .fa-chevron-down,
.preExpand-2 .accordion_card_3 .accordion__button .fa-chevron-down,
.preExpand-3 .accordion_card_4 .accordion__button .fa-chevron-down,
.preExpand-4 .accordion_card_5 .accordion__button .fa-chevron-down {
  display: none;
}

.preExpand-0 .accordion_card_1 .accordion__button .fa-chevron-up,
.preExpand-1 .accordion_card_2 .accordion__button .fa-chevron-up,
.preExpand-2 .accordion_card_3 .accordion__button .fa-chevron-up,
.preExpand-3 .accordion_card_4 .accordion__button .fa-chevron-up,
.preExpand-4 .accordion_card_5 .accordion__button .fa-chevron-up {
  display: block;
}

.collapsable-panel {
  flex: 0 0 4%;
  max-width: 4%;
  padding: 0;
}

.collapsable-panel .onlyicon {
  padding: 15px;
}
.workflow-loader-wrapper {
  position: absolute;
  box-sizing: border-box;
  height: 100%;
  background: #bcbcbc4d;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.workflow-loader-wrapper .workflow-loader {
  height: inherit !important;
  height: auto !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.customer-detail-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: center;
}
.message-text {
  max-width: calc(100vw - 400px);
  min-width: fit-content;
}

@media (max-width: 1235px) {
  .collapsable-panel .onlyicon {
    font-size: 16px;
  }

  .leftbaricon {
    width: 19px;
    height: 19px;
    padding: 2px 0;
    font-size: 11px;
  }
}

@media (max-width: 1220px) {
  .slide-left {
    flex: 0 0 92.5%;
    max-width: 92.5%;
  }

  .collapsable-panel {
    flex: 0 0 5.5%;
    max-width: 5.5%;
  }
}

@media (max-width: 1150px) {
  .right-col {
    flex: 0 0 calc(25% - 40px);
    max-width: calc(25% - 40px);
  }

  .right-col .details-accordion .accordion__button {
    font-size: 12px;
    padding: 16px 10px !important;
  }
}

@media (max-width: 970px) {
  .right-col {
    flex: 0 0 calc(25% - 25px);
    max-width: calc(25% - 25px);
  }
}
