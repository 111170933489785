@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
/* Wrapper and Settings */
body {
  font-size: 0.875rem;
  background: #f9fafb;
  font-family: "Lato", sans-serif;
}

.btngrad {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#499bea+0,207ce5+100;Blue+3d+%237 */
  background: rgb(73, 155, 234);
  /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(73, 155, 234, 1) 0%,
    rgba(32, 124, 229, 1) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(73, 155, 234, 1) 0%,
    rgba(32, 124, 229, 1) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(73, 155, 234, 1) 0%,
    rgba(32, 124, 229, 1) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#499bea', endColorstr='#207ce5', GradientType=0);
  /* IE6-9 */
}

#main-body {
  padding: 0;
}

#application-wrapper {
  margin: 0;
}

#application-wrapper .list-group a {
  font-size: 15px;
}

.logop-expanded {
  width: 300px;
}

.mp-0 {
  padding: 0;
  margin: 0;
}

.card {
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  transition: width 0.1s ease, left 0.1s ease;
  border: none;
}

.px-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.h2 {
  font-size: 20px;
}

/* Header */

#topHeader {
  background: #fff;
  margin: 0;
  padding: 4px 0;
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  transition: width 0.1s ease, left 0.1s ease;
  z-index: 1000;
}

.logo {
  width: 156px;
}

.logo-header {
  width: 100px;
  margin-left: 16px;
  vertical-align: middle;
}

.logo-header-mobile {
  display: none;
}
@media (max-width: 740px) {
  .hide-mobile {
    display: none;
  }
  .logo-header-mobile {
    width: 60px;
    display: block;
  }
}

.spacer {
  margin-top: 75px;
  margin-bottom: 15px;
}

.switchButton {
  font-size: 20px;
  text-decoration: none;
  margin-left: 0;
  padding-left: 0;
  text-transform: capitalize;
  color: #2a7fff;
  font-weight: 700;
}

.mt1 {
  margin-top: 1px;
}

.mt16 {
  margin-top: 16px;
}

.switchButton div {
  text-decoration: none;
  display: inline-block;
  line-height: 50px;
  padding-left: 15px;
  color: #2a7fff;
  vertical-align: middle;
}

.searchform .input-group div {
  width: 100%;
}

.searchform .input-group .searchbar {
  border: 1px solid #e4e4e4;
  font-size: 14px;
  border-radius: 25px;
  background: #f9fafb;
  padding: 10px 20px;
  height: 48px;
}

.searchform .input-group .searchbar:focus {
  box-shadow: 0px 0px 4px 0px #ddd;
}

.searchform .searchbar::placeholder {
  color: #495057;
}

.searchform .inputWithIcon i {
  top: 10px;
  right: 10px;
  color: #000;
}

.searchform .search-wrapper .suggestions,
.searchform .search-wrapper .no-suggestions {
  width: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 4px 0px #ddd;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.search-wrapper .suggestion-active,
.search-wrapper .suggestions li:hover {
  background-color: #007bff;
  color: #fff;
  font-weight: 400;
}

.searchform .search-wrapper .suggestions {
  max-height: 260px;
  box-shadow: 0px 0px 4px 0px #ddd;
  overflow: auto;
  background:
    /* Shadow covers */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

.searchform .search-wrapper .suggestions li,
.searchform .search-wrapper .no-suggestions {
  padding-left: 20px;
  border: none;
}

.searchform .search-wrapper input.focused-suggestion {
  border-radius: 24px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.searchform .search-wrapper .suggestions::-webkit-scrollbar {
  width: 0;
}

.suggestions .AP_Invoice:before {
  content: "\f571";
}

.suggestions .AR_Invoice:before,
.suggestions .OrderBacklog::before {
  content: "\f4c0";
}

.suggestions .Vendor:before {
  content: "\f507";
}

.suggestions .Customer::before {
  content: "\f0c0";
}

.suggestions .AP_Invoice:before,
.suggestions .AR_Invoice:before,
.suggestions .Vendor:before,
.suggestions .Customer::before,
.suggestions .OrderBacklog::before {
  font-family: "Font Awesome 5 Free";
  margin-right: 5px;
  font-weight: 900;
}

#sidebar-container .list-group a {
  text-transform: capitalize;
  font-weight: 500;
}

#sidebar-container .list-group a.activet {
  background: #f9fafb;
  color: #2a7fff;
  font-weight: 600;
}

#sideTabBar li.active a {
  color: #fff;
}

#profile::after {
  content: inherit;
}

#profile:focus {
  box-shadow: none;
}

#profilebox {
  padding: 0 15px;
  font-size: 14px;
  width: 250px;
  top: 32px;
  left: -12px;
  text-align: center;
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  transition: width 0.1s ease, left 0.1s ease;
}

#profilebox::before {
  right: 42px;
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  position: absolute;
  display: inline-block;
  content: "";
}

#profilebox::after {
  right: 43px;
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  position: absolute;
  display: inline-block;
  content: "";
}

#profilebox p {
  margin-top: 15px;
  font-weight: 500;
  color: #222;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#dtwrapper {
  border-top: 1px solid #ddd;
}

#dtwrapper a {
  color: #222;
  padding: 10px;
  display: block;
  text-transform: uppercase;
  font-size: 12px;
}

#dtwrapper a:hover {
  text-decoration: none;
  color: #2a7fff;
}

#dtwrapper a.align-right {
  text-align: right;
  font-size: 14px;
}

.usericon {
  width: 35px;
  display: inline-block;
  vertical-align: top;
}

.usericonBig {
  width: 75px;
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
}

.user-detail {
  display: inline-block;
  padding: 0px 5px;
}

.user-detail p {
  margin-bottom: 0;
}

.signout {
  font-style: italic;
}

/* Sidebar */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  /* Behind the navbar */
  padding: 0 0 0;
  /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  background: #1e1e2d;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
    background: #1e1e2d;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #fff;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #fff;
}

.sidebar .nav-link.active {
  color: #fff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

#sidebar-container {
  padding: 0;
  padding-top: 15px;
  transition: width ease-out 0.18s;
}

/* Sidebar sizes when expanded and expanded */
#sidebar-container.sidebar-expanded {
  width: 230px;
}

#sidebar-container.sidebar-collapsed {
  width: 39px;
  transition: width ease-out 0.18s;
}

/* Menu item*/
#sidebar-container .list-group a {
  height: 47px;
  color: #333;
  background: none;
  letter-spacing: 1px;
  font-weight: normal;
}

#sidebar-container .list-group-item {
  border: none;
}

/* Submenu item*/
#sidebar-container .list-group .sidebar-submenu a {
  height: 45px;
  padding-left: 30px;
}

.sidebar-submenu {
  font-size: 0.9rem;
}

/* Separators */
.sidebar-separator-title {
  background-color: #fff;
  height: 35px;
}

.sidebar-separator {
  background-color: #fff;
  height: 25px;
}

.logo-separator {
  background-color: #fff;
  height: 60px;
}

/* Closed submenu icon */
#sidebar-container
  .list-group
  .list-group-item[aria-expanded="false"]
  .submenu-icon::after {
  content: " \f0d7";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
}

/* Opened submenu icon */
#sidebar-container
  .list-group
  .list-group-item[aria-expanded="true"]
  .submenu-icon::after {
  content: " \f0da";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
}

.sidebar-collapsed .sidebarfixed {
  width: 39px;
}

.fntw {
  width: 15px;
}

.menufixed {
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0px;
}

.sidebarfixed {
  position: fixed;
  top: 94px;
  overflow-y: scroll;
  height: calc(100vh - 80px);
  padding-bottom: 20px;
  max-width: 230px;
}

.otherbody {
  margin-top: 80px !important;
}

.dcolumn8,
.dcolumn9 {
  width: 5%;
  text-align: center;
}

.dcolumn1 {
  width: 15%;
}

.dcolumn2 {
  width: 10%;
}

.dcolumn3 {
  width: 10%;
}

.dcolumn4 {
  width: 10%;
}

.dcolumn5,
.dcolumn6,
.dcolumn7 {
  width: 12%;
}

.paymentdetail {
  background: #fff;
  margin-bottom: 30px;
  padding: 30px 30px 30px 30px;
  box-shadow: 0px 5px 13px 0px #ddd;
  border-radius: 10px;
  min-height: 220px;
}

.paymentdetail span {
  float: left;
  width: 30%;
  margin-top: 8px;
  font-weight: 500;
  font-size: 15px;
}

.paymentdetail .form-control {
  width: 70%;
}

.paymentdetail .form-group {
  margin-bottom: 40px;
}

.settingbox {
  background: #fff;
  margin-bottom: 30px;
  padding: 20px;
  box-shadow: 0px 5px 13px 0px #ddd;
  border-radius: 10px;
  min-height: 220px;
  margin-right: 10px;
  flex: 1;
}

.settingbox h3 {
  font-size: 18px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.settingbox .settingboxlink p {
  margin-bottom: 8px;
}

.settingbox .settingboxlink p a,
.settingbox .settingboxlink p a:hover {
  text-decoration: none;
  font-weight: 500;
}

.btnsubmit {
  font-size: 14px;
  border: none;
  float: right;
}

.btnsubmit:hover,
.btnsubmit:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.pagepayable .select2-container .select2-selection--single {
  /*border: none;*/
  padding-left: 0;
}

.pagepayable .select2-container .select2-selection--single:focus {
  /*border: none;*/
  outline: none;
}

.pagepayable
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  /*color: #2a7fff;*/
  font-size: 14px;
}

.select2-container .select2-selection--single {
  padding: 6px;
  height: 36px;
  border: 1px solid #ced4da;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: normal;
  font-size: 15px;
}

.bredcrumbinner {
  border-radius: 10px;
  margin: 10px 0px 30px 0px;
  font-size: 16px;
  float: left;
}

.payingdet span {
  width: auto;
  padding-right: 30px;
}

.payingdet {
  float: left;
  width: 100%;
  background: #f9f9f9;
  padding: 10px;
  margin-bottom: 30px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}

.remove-space {
  margin-bottom: 0;
}

.space-box {
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
}

.setbtn {
  float: left;
  width: 100%;
  margin-top: 30px;
}

.setbtn.box-space {
  margin-top: 5px;
}

.setbtn .resetbtn,
.setbtn .resetbtn:hover,
.setbtn .resetbtn:focus {
  background: #e2e2e2;
  color: #222;
  font-size: 14px;
  min-width: 48%;
  text-align: center;
  float: left;
  border-radius: 0.25rem;
}

.setbtn .applybtn,
.setbtn .applybtn:hover,
.setbtn .applybtn:focus {
  color: #fff;
  width: 48%;
  background: #2a7fff;
  text-align: center;
  font-size: 14px;
  float: right;
  border-radius: 0.25rem;
}

.settingbox1 {
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 30px;
  padding: 20px;
  min-height: 220px;
}

.settingbox1 h3 {
  font-size: 18px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.settingbox1 .settingboxlink1 p {
  margin-bottom: 8px;
}

.settingbox1 .settingboxlink1 p a,
.settingbox1 .settingboxlink1 p a:hover {
  text-decoration: none;
  font-weight: 500;
}

.colorwireframestatic {
  position: fixed;
  top: 9%;
  z-index: 1000;
  float: left;
  width: 80.4%;
  background: #fff;
}

.custchattabhead {
  font-size: 18px;
  color: #333;
  font-weight: normal;
}

.custchattab1 {
  margin: 30px 0px;
}

.custtabpanel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custchattab1 span {
  background: #f9f9f9;
  padding: 6px 20px;
  cursor: pointer;
  margin: 2px;
  white-space: nowrap;
  margin-bottom: 7px;
}

.custtabpanel label {
  padding: 6px;
}

.custtabpanel label span {
  background: unset;
  padding: unset;
  cursor: unset;
  margin: unset;
}

.insightsExport span {
  background: #f9f9f9;
  padding: 6px 20px;
  cursor: pointer;
  margin: 2px;
}

.custchattab1 span:focus,
.custchattab1 span:hover,
.insightsExport span:focus,
.insightsExport span:hover {
  background: #ddd;
  outline: none;
}

.custchattab1 span.activec {
  background: linear-gradient(
    to bottom,
    rgba(73, 155, 234, 1) 0%,
    rgba(32, 124, 229, 1) 100%
  ) !important;
  color: #fff;
}

.spend-category-chart .recharts-legend-item {
  cursor: pointer;
}

/* Custom */
.text-icon-big {
  background: linear-gradient(
    to bottom,
    rgba(73, 155, 234, 1) 0%,
    rgba(32, 124, 229, 1) 100%
  );
  height: 30px;
  width: 30px;
  padding-top: 4px;
  font-size: 15px;
  font-weight: bold;
  color: white;
  display: inline-block;
}
.text-icon-big.xl {
  height: 40px;
  width: 40px;
  padding-top: 7px;
  font-size: 20px;
}
/* Accordion card styling */

.customer-card .card-wrapper {
  margin-bottom: 15px;
}

.customer-card .card-wrapper:last-child {
  margin-bottom: 0;
}

.vendor-card label,
.customer-card label {
  width: 40%;
}

.vendor-card label + div,
.vendor-card label + select,
.customer-card label + div,
.customer-card label + select {
  width: 50%;
  float: right;
  text-align: left;
}

.custom-table.accuracy-table th:first-child {
  width: 10%;
}

.custom-table.accuracy-table td,
.custom-table.accuracy-table th {
  text-align: center;
}

.mapping-modal .modal-dialog {
  max-width: initial;
  width: 60%;
}

.mapping-modal .position-sticky {
  top: 0;
  z-index: 1;
}

.mapping-modal .modal-body {
  padding-top: 0;
}

.aging-toggle-amount input[type="checkbox"] {
  height: 16px;
  width: 16px;
}
.pl-0 {
  padding-left: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
