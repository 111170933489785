.react-datepicker-component .react-datepicker-input {
  background: transparent;
}

.react-datepicker .react-datepicker-container {
  font-family: arial;
}


.react-datepicker-component .react-datepicker-input:hover {
  background: transparent;
}

.react-datepicker-component .react-datepicker-input input {
  color: #333 !important;
  font-weight: 500;
}

.react-datepicker-component .react-datepicker-input::placeholder::-ms-input-placeholder {
  color: #333;
  font-weight: 600;
}

.react-datepicker-component .react-datepicker-input.is-open {
  background: transparent;
  border: 1px solid #ced0da;
}

.react-datepicker .react-datepicker-container .react-datepicker-top {
  background: white;
  color: #333;
  border-top: 1px solid #d4d4d4;
  border-left: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
}

.react-datepicker.floating {
  box-shadow: none;
  background: #fff;
  border-radius: 5px;
}
.react-datepicker .react-datepicker-container .react-datepicker-top .display .react-datepicker-button:hover {
  background: white;
}

.react-datepicker .react-datepicker-container .react-datepicker-top .display .react-datepicker-button strong {
  font-weight: bold;
}

.react-datepicker .react-datepicker-container .react-datepicker-top .week-days .week-day {
  font-weight: bold;
}

.react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row:not(:last-child) {
  border-bottom: none;
}

.react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker:not(:last-child) {
  border-right: none;
}

.react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker {
  color: #333;
  font-weight: 400;
}

.react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker.current {
  font-weight: 400;
  color: #333;
  background: #fff;
}

.react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker.selected.current {
  color: #fff;
  background: #357ebd;
}

.react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker.day {
  min-height: 34px !important;
}

.react-datepicker-component .react-datepicker-input .button-wrapper .input-button {
  color: #333;
}

.react-datepicker-component .react-datepicker-input .button-wrapper .input-button:hover {
  color: #333;
}

.react-datepicker-component .react-datepicker-input .button-wrapper:hover {
  color: #333;
}

@media (max-width: 767px) {
  div.daterangepicker:not(.single).show-calendar,
  div.daterangepicker {
    width: 100%!important;
    margin: -10px auto 0;
    position: static!important;
  }
  .daterangepicker .drp-calendar.right,
  .daterangepicker .drp-calendar.left {
    margin: 10px auto;
    padding: 0;
    border: 1px solid #ddd;
  }
  .daterangepicker.opensright:after,
  .daterangepicker.opensright:before {
    display: none;
  }
  .daterangepicker.show-calendar .drp-buttons {
    position: sticky;
    bottom: 0;
    background-color: #fff;
  }
}

.react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker.current.disabled {
  color:  #9098a7;
}

.react-daterange-picker__inputGroup, .react-date-picker__inputGroup {
  padding: 0 2px !important;
}
.react-daterange-picker__button, .react-date-picker__button {
  padding: 4px 2px !important;
}
.react-daterange-picker__calendar, .react-date-picker__calendar {
  width: 284px !important;
  max-width: 284px !important;
  border-radius: 4px;
  top: 100% !important;
}
.react-daterange-picker__inputGroup__input:invalid {
  background: transparent !important;
}
.react-date-picker__wrapper, .react-daterange-picker__wrapper {
  font-size: 14px !important;
  padding: 8px 8px 6px 8px !important;
  border: 0px !important;
  border-radius: 4px;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon {
  stroke: #1e8ced !important;
}
.react-date-picker__inputGroup__input:invalid {
  background: #fff !important;
}
.react-calendar__month-view__days__day--weekend {
  color: #1e8ced !important;
}
.react-date-picker * {
  text-decoration: none !important;
}
.react-daterange-picker__button svg, .react-date-picker__button svg {
  height: 16px !important;
  width: 16px !important;
  stroke: #545556 !important;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon, .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7 !important;
}
.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon, .react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
  stroke: #0078d7 !important;
}
.react-daterange-picker * {
  text-decoration: none !important;
}
abbr[title] {
  text-decoration: none !important;
}
.react-daterange-picker__inputGroup__input {
  color: #010709 !important;
}
.react-calendar button {
  font-size: 12px !important;
  letter-spacing: 0px !important;
}
.react-calendar__tile--hasActive {
  background: #1e8ced !important;
  color: #fff !important;
}
.react-calendar {
  border: thin solid #cacaca !important;
}
.react-daterange-picker__range-divider {
  position: relative;
  top: 2px;
}
.react-daterange-picker, .react-date-picker {
  width: 100%;
  border: solid 1px #cacaca;
  border-radius: 4px;
  height: 40px;
}
.react-daterange-picker__clear-button, .react-date-picker__clear-button {
  position: absolute;
  right: 32px;
  padding: 3px 2px !important;
}
.react-daterange-picker__calendar-button, .react-date-picker__calendar-button {
  position: absolute;
  right: 8px;
}
.react-date-picker__inputGroup {
  min-width: 150px;
}
.react-date-picker__inputGroup__input:invalid {
  background: #fff !important;
}
.react-calendar__navigation button {
  background: none;
  min-width: 38px !important;
}
.react-calendar__navigation__label {
  min-width: 90px !important;
}
.calenderTop {
  top: 100% !important;
  bottom: unset !important;
}
.daterangepicker select {
  padding: 0px 4px 0px 4px !important;
  border: 0.5px solid #cdcdcd;
}
.daterangepicker select:focus {
  outline: none;
}
