.autocomplete {
    input {
      border: 1px solid #ced4da;
      padding: 0.5rem;
      padding-right: 30px;
      width: 100%;
      font-size: 12px;
      border-radius: 4px;
    }
    .no-suggestions {
      color: #999;
      padding: 0.5rem;
      background-color: #fff;
      position: absolute;
      z-index: 1;
      width: 100%;
    }
    .suggestions {
      border: 1px solid #ced4da;
      border-top-width: 0;
      list-style: none;
      margin-top: 0;
      max-height: 143px;
      overflow-y: auto;
      padding-left: 0;
      width: 100%;
      font-size: 12px;
      background-color: #fff;
      position: absolute;
      z-index: 1;
      &.hide {
        display: none;
      }
    }
    .suggestions li {
      padding: 0.5rem;
    }
    .suggestion-active,
    .suggestions li:hover {
      background-color: #cccccc;
      color: #000000;
      cursor: pointer;
      font-weight: 700;
    }
    .suggestions li:not(:last-of-type) {
      border-bottom: 1px solid #999;
    }
}
