.table100.ver1 {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -o-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -ms-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
}

.table100 {
    position: relative;
    padding-top: 60px;
}

.table100 {
    background-color: #fff;
}

.table100-head {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

table {
    width: 100%;
}

.table100.ver1 th {
    cursor: pointer;
    font-size: 13px;
    color: #fff;
    line-height: 1.4;
    background-color: #2a7fff;
}

.table100-head th {
    padding-top: 16px;
    padding-bottom: 16px;
}

.table100.ver1 td {

    font-size: 13px;
    color: gray;
    line-height: 1.4;
}

.table100-body td {
    padding-top: 14px;
    padding-bottom: 14px;
}

.table100.ver1 .table100-body tr:nth-child(even) {
    background-color: #f8f6ff;
}

.table100 .ps__rail-y {
    width: 9px;
    background-color: transparent;
    opacity: 1 !important;
    right: 5px;
}

.filtericon {
    width: 12px;
}

td {
    padding: 3px;
    /*text-align: center;*/
}

th {
    padding: 0px;
    /*text-align: center;*/
}

.column1 {
    width: 11.87%;
    padding-left: 15px;
}

.column2 {
    width: 11.87%;
    padding-left: 15px;
}

.column3 {
    width: 11.87%;
    text-align: center;
}

.column4 {
    width: 11.87%;
    text-align: center;
}

.column5 {
    width: 11.87%;
    text-align: center;
}

.column6 {
    width: 11.87%;
    padding-left: 25px;
}

.column7 {
    width: 11.87%;
}

.column8 {
    width: 5%;
    padding-left: 25px;
}

.column9 {
    width: 11.87%;
    padding-left: 30px;
}

.filterone #exportlist a {
    text-align: left;
}

.filterone {
    margin-bottom: 30px;
}

.filterone .btn, .filterone .btn:hover {
    padding: 10px 24px;
    border-radius: 0px;
    outline: none;
}

.filterone #export {
    margin-left: 17px;
}

.filtertwo {
    margin: 0px 15px 10px 15px;
    box-shadow: 0px 2px 8px 0px #ddd;
}

.filtertwo label {
    margin-bottom: 0px;
    font-weight: 500;
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 0px;
    padding-left: 0px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}

.editdelete {
    border: none;
    font-size: 14px;
}

.editdelete:hover {
    border: none;
    font-size: 14px;
}

.editdelete:focus {
    border: none;
    font-size: 14px;
    box-shadow: none;
}

.editdeletelist {
    font-size: 12px;
    text-align: left;
    width: 100px;
    left: 10px !important;
}

.editdeletelist::before {
    right: 9px;
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    position: absolute;
    display: inline-block;
    content: '';
}

.editdeletelist::after {
    right: 10px;
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    position: absolute;
    display: inline-block;
    content: '';
}

.editdeletelist a {
    font-size: 12px;
    text-align: left;
    color: #222;
    padding: 8px 12px;
    display: block;
}

.editdeletelist a:hover {
    text-decoration: none;
    background: #eee;
}

/**/
.filterdrop {
    border: none;
    font-size: 14px;
}

.filterdrop:hover {
    border: none;
    font-size: 14px;
}

.filterdrop:focus {
    border: none;
    font-size: 14px;
    box-shadow: none !important;
}

.filterdroplist {
    font-size: 12px;
    text-align: left;
    width: 500px;
    padding: 15px;
}

.filterdroplist.normal {
    width: none !important;
}

.filterdroplist::before {
    left: 9px;
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    position: absolute;
    display: inline-block;
    content: '';
}

.filterdroplist::after {
    left: 10px;
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    position: absolute;
    display: inline-block;
    content: '';
}

.filterdroplist a {
    font-size: 12px;
    text-align: left;
    color: #222;
    padding: 8px 12px;
    display: block;
}

.filterdroplist a:hover {
    text-decoration: none;
    background: #eee;
}

/**/
.payexpdrop {
    border: none;
    font-size: 14px;
}

.payexpdrop:hover {
    border: none;
    font-size: 14px;
}

.payexpdrop:focus {
    border: none;
    font-size: 14px;
    box-shadow: none !important;
}

.payexpdroplist {
    font-size: 12px;
    text-align: right;
}

.payexpdroplist::before {
    right: 9px;
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    position: absolute;
    display: inline-block;
    content: '';
}

.payexpdroplist::after {
    right: 10px;
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    position: absolute;
    display: inline-block;
    content: '';
}

.payexpdroplist a {
    font-size: 12px;
    text-align: right;
    color: #222;
    padding: 8px 12px;
    display: block;
}

.payexpdroplist a:hover {
    text-decoration: none;
    background: none;
    color: initial;
}

/**/
.payinnerdrop {
    border: none;
    font-size: 14px !important;
    color: #2a7fff !important;
    padding: 5px 0px 8px 0px !important;
}

.payinnerdrop:hover {
    border: none;
    font-size: 14px;
    background: none !important;
}

.payinnerdrop:focus {
    border: none;
    font-size: 14px;
    box-shadow: none !important;
}

.payinnerdroplist {
    font-size: 12px;
    text-align: left;
}

/*.payinnerdroplist::before {
  left: 9px;
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  position: absolute;
  display: inline-block;
  content: '';
}
.payinnerdroplist::after {
  left: 10px;
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  position: absolute;
  display: inline-block;
  content: '';
}*/
.payinnerdroplist a {
    font-size: 12px;
    text-align: left;
    color: #222;
    padding: 8px 12px;
    display: block;
}

.payinnerdroplist a:hover {
    text-decoration: none;
    background: #eee;
}

.tablepaging {
    float: right;
    margin: 15px 15px 10px 0px;
}

.tablepaging .pagination {
    list-style: none;
    display: inline-block;
    padding: 0;
}

.tablepaging .pagination li {
    display: inline;
    text-align: center;
}

.tablepaging .pagination a {
    float: left;
    display: block;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    padding: 5px 12px;
    color: #fff;
    margin-left: -1px;
    border: 1px solid transparent;
    line-height: 1.5;
}

.tablepaging .modal {
    position: inherit;
    margin-bottom: 0px;
    min-height: 40px;
    margin-top: 6px;
}

.tablepaging .pagination a:active {
    outline: none;
}

.tablepaging .modal a {
    margin: 0 5px;
    padding: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 100%;
    background-color: #2a7fff;
    transform: translateY(0);
    transition: all .3s ease-out;
}

.tablepaging .modal a.prev {
    border-radius: 15px 0 0 15px;
    width: 40px;
}

.tablepaging .modal a.next {
    border-radius: 0 15px 15px 0;
    width: 40px;
}

.tablepaging .modal a:hover {
    background-color: #969696;
    color: #fff;
    font-weight: 1000;
    /*transform: translateY(8px);
    transition: all .3s ease-out;*/
}

.tablepaging .modal a.active, .tablepaging .modal a:active {
    background-color: #969696;
}

.criticalclr {
    background: #ff0000;
    color: #fff;
    border-radius: 50px;
    width: 13px;
    height: 13px;
    display: inline-block;
    margin: 0px 3px -2px 0px;
}

.default {
    background: #efeaea;
    color: #fff;
    border-radius: 50px;
    width: 13px;
    height: 13px;
    display: inline-block;
    margin: 0px 3px -2px 0px;
}

.lowclr {
    background: #7BCA03;
    color: #fff;
    border-radius: 50px;
    width: 13px;
    height: 13px;
    display: inline-block;
    margin: 0px 3px -2px 0px;
}

.highclr {
    background: #F87C02;
    color: #fff;
    border-radius: 50px;
    width: 13px;
    height: 13px;
    display: inline-block;
    margin: 0px 3px -2px 0px;
}

.mediumclr {
    background: #ffb52d;
    color: #fff;
    border-radius: 50px;
    width: 13px;
    height: 13px;
    display: inline-block;
    margin: 0px 3px -2px 0px;
}

.commondiv {
    padding: 20px;
    margin: 0px 4px;
    border-radius: 8px;
}

.filter1 {
    background: linear-gradient(to bottom, #FDFDFD 0%, #E6E6E6 100%) !important;
    border: 1px solid #999999;
    cursor: pointer;
    color: black;
}

.filter1:hover {
    background: #E6E6E6;
    transition: 0.3s;
}

.filter1 .commondiv-div1 {
    font-weight: 500;
    font-size: 16px;
}

.filter1 .commondiv-div2 {
    font-weight: 600;
    font-size: 18px;
}

.filter2 {
    background: linear-gradient(to bottom, #FC0D1B 0%, #DA020F 100%) !important;
    border: 1px solid #D40000;
    cursor: pointer;
}

.filter2:hover {
    opacity: 0.8;
    transition: 0.3s;
}

.filter2 .commondiv-div1 {
    font-weight: 500;
    font-size: 16px;
    color: #fff;
}

.filter2 .commondiv-div2 {
    font-weight: 600;
    font-size: 18px;
    color: #fff;
}

.filter3 {
    background: linear-gradient(to bottom, #F67C23 0%, #D86008 100%) !important;
    border: 1px solid #D45500;
    /* background: #F87C02; */
    cursor: pointer;
}

.filter3:hover {
    opacity: 0.8;
    transition: 0.3s;
}

.filter3 .commondiv-div1 {
    font-weight: 500;
    font-size: 16px;
    color: #fff;
}

.filter3 .commondiv-div2 {
    font-weight: 600;
    font-size: 18px;
    color: #fff;
}

.filter4 {
    background: linear-gradient(to bottom, #FEB43F 0%, #FEA418 100%) !important;
    border: 1px solid #FF9955;
    /* background: #ffb52d; */
    cursor: pointer;
}

.filter4:hover {
    opacity: 0.8;
    transition: 0.3s;
}

.filter4 .commondiv-div1 {
    font-weight: 500;
    font-size: 16px;
    color: #fff;
}

.filter4 .commondiv-div2 {
    font-weight: 600;
    font-size: 18px;
    color: #fff;
}

.filter5 {
    background: linear-gradient(to bottom, #7EC826 0%, #66A21F 100%) !important;
    border: 1px solid #5AA02C;
    /* background: #7BCA03; */
    cursor: pointer;
}

.filter5:hover {
    opacity: 0.8;
    transition: 0.3s;
}

.filter5 .commondiv-div1 {
    font-weight: 500;
    font-size: 16px;
    color: #fff;
}

.filter5 .commondiv-div2 {
    font-weight: 600;
    font-size: 18px;
    color: #fff;
}

.filterthree {
    padding: 15px;
    margin-bottom: 10px;
    color: #b0b0b0;
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    float: right;
    width: 100%;
}

.filterthree span.lefticon {
    color: #b0b0b0;
    padding: 0px 3px;
    cursor: pointer;
}

.filterthree span.lefticon b {
    color: #000;
}

.filterthree span.righticon {
    color: #000;
    padding: 0px 3px;
    cursor: pointer;
}

.filterdroplist label {
    font-size: 14px;
}

.colorcomb {
    color: #2a7fff !important;
}

.filterdroplist select.form-control {
    width: 100%;
    padding-left: 10px;
    /*color: #2a7fff;*/
    font-size: 14px;
    min-width: 102px;
}

.filterdroplist select.form-control:hover, .filterdroplist select.form-control:focus {
    outline: none;
    box-shadow: none;
}

/*from HTML */

.lookgrid {
    margin-top: -5px;
}

.lookgrid a {
    width: 100%;
    display: inline-block;
    padding: 12px 0px;
    /*border-bottom: 1px solid #ddd;*/
    -webkit-box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
    color: #333;
    font-size: 13px;
}

.lookgrid a:hover {
    text-decoration: none;
    background: #f9f9f9;
    /*box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);*/
    box-shadow: 0px 2px 5px 0px #dadce0;
    color: #333;
}

.lookgrid .tcolumn1 {
    width: 16%;
    display: inline-block;
    vertical-align: middle;
}

.lookgrid .tcolumn2 {
    width: 13%;
    display: inline-block;
    vertical-align: middle;
}

.lookgrid .tcolumn3 {
    width: 10%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

.lookgrid .tcolumn4 {
    width: 17%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

.lookgrid .tcolumn5 {
    width: 12%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

.lookgrid .tcolumn6 {
    width: 12%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

.lookgrid .tcolumn7 {
    width: 12%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

.lookgrid .tcolumn8 {
    width: 3%;
    display: inline-block;
    padding-left: 10px;
    vertical-align: middle;
}

.payablenewfiltercnt .commondiv {
    padding: 10px;
}

.payablenewfiltercnt .filter1 {
    transition: all 0.5s;
    margin-left: 0;
}

.payablenewfiltercnt .filter1:hover {
    margin-top: -10px;
    box-shadow: 4px 8px 5px 0px #ddd;
}

.payablenewfiltercnt .filter1:hover:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 10px;
    width: 100%;
}

.payablenewfiltercnt .filter2 {
    transition: all 0.5s;
}

.payablenewfiltercnt .filter2:hover {
    margin-top: -10px;
    box-shadow: 4px 8px 5px 0px #ff0000;
}

.payablenewfiltercnt .filter2:hover:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 10px;
    width: 100%;
}

.payablenewfiltercnt .filter3 {
    transition: all 0.5s;
}

.payablenewfiltercnt .filter3:hover {
    margin-top: -10px;
    box-shadow: 4px 8px 5px 0px #F87C02;
}

.payablenewfiltercnt .filter3:hover:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 10px;
    width: 100%;
}

.payablenewfiltercnt .filter4 {
    transition: all 0.5s;
}

.payablenewfiltercnt .filter4:hover {
    margin-top: -10px;
    box-shadow: 4px 8px 5px 0px #ffb52d;
}

.payablenewfiltercnt .filter4:hover:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 10px;
    width: 100%;
}

.payablenewfiltercnt .filter5 {
    transition: all 0.5s;
    margin-right: 0;
}

.payablenewfiltercnt .filter5:hover {
    margin-top: -10px;
    box-shadow: 4px 8px 5px 0px #7BCA03;
}

.payablenewfiltercnt .filter5:hover:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 10px;
    width: 100%;
}

.payablenewtable .dcolumn1 {
    width: 17%;
}

.payablenewtable .dcolumn2 {
    width: 13%;
    padding-left: 8px;
}

.payablenewtable .dcolumn3 {
    width: 13%;
    padding-left: 8px;
}

.payablenewtable .dcolumn4 {
    width: 13%;
}

.payablenewtable .dcolumn5 {
    width: 12%;
}

.payablenewtable .dcolumn6 {
    width: 12%;
    padding-left: 15px;
}

.payablenewtable .dcolumn7 {
    width: 12%;
    padding-left: 20px;
}

/*additional*/

.lookgrid div {
    width: 100%;
    display: inline-block;
    padding: 12px 0px;
    /*border-bottom: 1px solid #ddd;*/
    -webkit-box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
    color: #333;
    font-size: 13px;
}

.lookgrid div:hover {
    text-decoration: none;
    background: #f9f9f9;
    /*box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);*/
    box-shadow: 0px 2px 5px 0px #dadce0;
    color: #333;
}

.lookgrid span {
    margin-left: 4px;
}

.payablenewfiltercnt.sticky {
    position: sticky;
    top: 67px;
    z-index: 10;
    width: 100%;
    background: #fff;
    margin-left: 0;
    margin-right: 0;
}

.rank-wrapper {
    margin: 30px 0 15px;
}

.sticky .rank-wrapper {
    padding: 0;
}

.emoji-section {
    text-align: center;
    cursor: pointer;
}

.emoji-section img {
    width: 25px;
}

.emoji-icon-container {
    width: 115px;
    padding: 5px;
    display: none;
    position: absolute;
    top: -18px;
    right: auto;
    list-style-type: none;
    background: #eee;
    border-radius: 25px;
    z-index: 0;
}

.emoji-icon-container img {
    width: 25px;
    margin: 5px 5px 0px 5px;
    vertical-align: middle;
    border-style: none;
}

.emoji-icon-container li {
    float: left;
}

.emoji-icon-container.show {
    display: block;
}

.form-control .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 8px 4px;
    padding-right: 30px;
    font-size: 12px;
}

.form-control .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0 8px;
}

.form-control .MuiAutocomplete-popupIndicatorOpen {
    transform: none;
}

.daterangepicker:not(.single).show-calendar {
    width: 650px;
}


.recivablefilter .criticalclr {
    background: #ff0000;
}

.recivablefilter .lowclr {
    background: #F87C02;
}

.recivablefilter .highclr {
    background: #2b861a;
}

.recivablefilter .mediumclr {
    background: #07bd26;
}

/* Custom added */
.col-xs-6ths,
.col-sm-6ths,
.col-md-6ths,
.col-lg-6ths {
    position: relative;
    min-height: 1px;
    padding-right: 0px;
    padding-left: 0px;
}

@media (min-width: 768px) {
    .col-sm-6ths {
        width: 16.66%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-6ths {
        width: 16.66%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-6ths {
        width: 16.66%;
        float: left;
    }
}

.followupdiv {
    padding: 40px 0px;
    border-top: 1px solid #ddd;
    margin: 50px 0px 0px;
}

.followupdiv label {
    font-weight: 500;
}

.followuphead {
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 15px;
}

.followupdiv table {
    border-collapse: collapse;
    width: 100%;
}

.followupdiv td, .followupdiv th {
    border: 1px solid #ececec;
    text-align: left;
    padding: 8px;
    color: #333;
}

.followupdiv th {
    background: #2a7fff;
    color: #fff;
    font-weight: normal;
    font-size: 13px;
}

.followupdiv tr:nth-child(even) {
    background-color: #ececec;
}

.error {
    color: red;
}

.form-control.error,
.form-control.error:focus {
    color: #495057;
    border-color: red;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, .5);
}
