.arworkflow-main {
    .step-box {
        width: 400px;
        border-radius: 10px;
        background-color: #fff;
        font-size: 12px;
    }
    .box-header {
        color: #fff;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        background: linear-gradient(to bottom, #777777 0%, #444444 100%);
    }
    .sequence-wrapper {
        width: 250px;
    }

    .v-connector {
        width: 40px;
        .circle {
            width: 30px;
            height: 30px;
            border: 3px solid #000;
            text-align: center;
            border-radius: 50%;
            margin: 0 auto;
        }
        .line {
            width: 3px;
            height: 50px;
            margin: 0 auto;
            background: linear-gradient(to bottom, #777777 0%, #444444 100%);
        }
    }

    .h-connector {
        width: 200px;
        .line,
        .vl-line,
        .vr-line {
            width: 3px;
            height: 50px;
            margin: 0 auto;
            background: linear-gradient(to bottom, #777777 0%, #444444 100%);
        }
        .yes-label,
        .no-label {
            bottom: 0;
        }
        .yes-label {
            left: -10px;
            color: #000;
            background-color:green;
        }
        .no-label {
            right: -10px;
            color: #000;
            background-color: red;
        }
        .h-line {
            width: 200px;
            height: 3px;
            background: linear-gradient(to bottom, #777777 0%, #444444 100%);
        }
        .vl-line {
            float: left;
        }
        .vr-line {
            float: right;
        }
    }
    .days-input {
        width: 50px;
    }
    .full-page-spinner {
        position: absolute;
        width: 100%;
        height: 80%;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.5);
    }
}

.workflow-bar {
    padding: 15px 20px;
    -webkit-box-shadow: 1px 1px 2px 0px #b0aaaa;
    -moz-box-shadow: 1px 1px 2px 0px #b0aaaa;
    box-shadow: 1px 1px 2px 0px #b0aaaa;
}

.email-option {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ccc;
    display: block;
    text-align: center;
    i {
        font-size: 25px;
    }
}

.workflow-bar {
    color: #333;
    .users-icon {
        text-align: center;
        font-size: 20px;
    }
    .button-border {
        border: 1px solid #000;
        box-shadow: 1px 1px 1px 0 #726e6e;
    }
    .edit-icon {
        &:hover {
            background-color: #17ac45;
            color: #fff
        }
    }
    .delete-icon:not(:disabled) {
        &:hover {
            color: #fff;
            background-color:#c22121;
        }
    }
}
.row-green {
    background: #13E354;
    background: -webkit-linear-gradient(left, #fff, #13e354);
    background: -moz-linear-gradient(left, #fff, #13e354);
    background: linear-gradient(to right, #fff, #13e354);
}
.row-orange {
    background: #fff;
    background: -webkit-linear-gradient(left, #fff, #fd7e14);
    background: -moz-linear-gradient(left, #fff, #fd7e14);
    background: linear-gradient(to right, #fff, #fd7e14);
}
.row-red {
    background: #fff;
    background: -webkit-linear-gradient(left, #fff, #dc3545);
    background: -moz-linear-gradient(left, #fff, #dc3545);
    background: linear-gradient(to right, #fff, #dc3545);
}
.row-gray {
    background: #fff;
    background: -webkit-linear-gradient(left, #fff, #f5f5f5);
    background: -moz-linear-gradient(left, #fff, #f5f5f5);
    background: linear-gradient(to right, #fff, #f5f5f5);
}

.workflow-email-body .ck-editor__editable {
    min-height: 120px !important;
}
