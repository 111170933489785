#dashboardBody {
  padding: 10px 0;
  margin: 0;
}
#dashboardBody > .col-md-12 {
  padding-left: 0;
}

.all-account {
  background: none;
  color: #222;
  border: none;
  font-size: 14px;
  padding-left: 0;
}

.all-account:hover {
  background: none;
  color: #1e1e2d;
  border: none;
  font-size: 14px;
}

.all-account:focus {
  background: none;
  color: #1e1e2d;
  border: none;
  font-size: 14px;
  box-shadow: none;
}

#export {
  border: none;
  font-size: 14px;
  margin-left: 10px;
}

#exportlist {
  font-size: 12px;
  text-align: right;
  width: 100px;
}

#exportlist a {
  font-size: 12px;
  text-align: right;
  color: #222;
  padding: 8px 12px;
  display: block;
}

#exportlist a:hover {
  text-decoration: none;
  background: #eee;
}

#exportlist::before {
  right: 9px;
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  position: absolute;
  display: inline-block;
  content: "";
}

#exportlist::after {
  right: 10px;
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  position: absolute;
  display: inline-block;
  content: "";
}

#export:hover {
  border: none;
  font-size: 14px;
}

#export:focus {
  border: none;
  font-size: 14px;
  box-shadow: none;
}

#companylist {
  font-size: 12px;
  text-align: left;
}

#companylist a {
  font-size: 12px;
  text-align: left;
  color: #222;
  padding: 8px 12px;
  display: block;
}

#companylist a:hover {
  text-decoration: none;
  background: #eee;
}

#companylist::before {
  left: 9px;
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  position: absolute;
  display: inline-block;
  content: "";
}

#companylist::after {
  left: 10px;
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  position: absolute;
  display: inline-block;
  content: "";
}

.tb-border {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
  margin-bottom: 25px;
}

#tabBar {
  margin-bottom: 25px;
}

#tabBar li {
  padding: 10px 40px;
}

#tabBar li.active {
  background: #007bff;
}

#tabBar li a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
}

#tabBar li.active a {
  color: #fff;
}

.daterangepicker {
  font-family: "Poppins", sans-serif;
}

.liquidity-chart-dashboard {
  margin: 0 auto;
}

.liquidity-chart-dashboard svg {
  overflow: visible;
}

/************************** Right Sidebar ******************************/

#sideTabBar {
  margin-bottom: 25px;
  margin-top: 25px;
}

#sideTabBar li {
  padding: 10px 15px;
}

#sideTabBar li.active {
  background: #007bff;
}

#sideTabBar li a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
}

#dateRoller {
  text-align: center;
}

#prevDate {
  font-size: 16px;
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
}

#nextDate {
  font-size: 16px;
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
}

#showDate {
  font-size: 16px;
}

#pLisitng .card {
  box-shadow: none;
  border: 1px solid #ddd;
  padding: 5px 15px;
  margin-bottom: 10px;
  border-left: 10px solid #ddd;
}

#pLisitng .critical {
  border: 1px solid #ff0000;
  border-left: 10px solid #ff0000;
}

#pLisitng .average {
  border: 1px solid #cc9900;
  border-left: 10px solid #cc9900;
}

.mData {
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 15px;
}

.company_name {
  width: 70%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.smData {
  font-size: 12px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

#rLisitng .card {
  box-shadow: none;
  border: 1px solid #ddd;
  padding: 5px 15px;
  margin-bottom: 10px;
  border-left: 10px solid #ddd;
}

#rLisitng .critical {
  border: 1px solid #ff0000;
  border-left: 10px solid #ff0000;
}

#rLisitng .average {
  border: 1px solid #cc9900;
  border-left: 10px solid #cc9900;
}

.table100-body1 {
  max-height: 585px;
  overflow: auto;
}
.table100-body2 {
  max-height: 535px;
  overflow: auto;
}
.setminhgt-tblbd2 {
  /* min-height: 688px; */
}
.js-pscroll {
  position: relative;
  overflow: hidden;
}
.table100 .ps__rail-y {
  width: 9px;
  background-color: transparent;
  opacity: 1 !important;
  right: 5px;
  display: none;
}
.table100.ver1 .ps__rail-y::before {
  background-color: #ebebeb;
}
.table100 .ps__rail-y::before {
  content: "";
  display: block;
  position: absolute;
  background-color: #ebebeb;
  border-radius: 5px;
  width: 100%;
  height: calc(100% - 30px);
  left: 0;
  top: 15px;
}
.table100 .ps__rail-y .ps__thumb-y {
  width: 100%;
  right: 0;
  background-color: transparent;
  opacity: 1 !important;
}
.table100.ver1 .ps__rail-y .ps__thumb-y::before {
  background-color: #ccc;
}
.table100 .ps__rail-y .ps__thumb-y::before {
  content: "";
  display: block;
  position: absolute;
  background-color: #ccc;
  border-radius: 5px;
  width: 100%;
  height: calc(100% - 30px);
  left: 0;
  top: 15px;
}

/* my added */
div.dropdown-menu.export-btn {
  z-index: 2;
}

#companylist span {
  font-size: 12px;
  text-align: left;
  color: #222;
  padding: 8px 12px;
  display: block;
}

#companylist span:hover {
  text-decoration: none;
  background: #eee;
}

#exportlist span {
  font-size: 12px;
  text-align: right;
  color: #222;
  padding: 8px 12px;
  display: block;
}

#exportlist span:hover {
  text-decoration: none;
  background: #eee;
}

#sideTabBar li.active {
  /* background: #007bff; */
  color: white;
}

.react-bootstrap-daterangepicker-container {
  float: right;
}

.single-date-picker .react-bootstrap-daterangepicker-container {
  float: none;
  width: 100% !important;
  font-family: Poppins, sans-serif;
}

.single-date-picker .react-bootstrap-daterangepicker-container #wrap-date {
  height: 38px;
  padding: 8px 8px !important;
  border-radius: 4px;
}

.col.pay-rec-wrap {
  width: 74%;
  margin-left: 11px;
}
.col-md-1.dashboard-sidebar-collapsed-wrap {
  flex: 0 0 4.1%;
  max-width: 4.1%;
  padding: 0;
}

.dashboard-sidebar #sideTabBar li {
  padding: 8px 7px;
  font-size: 13px;
}
.dashboard-sidebar #showDate {
  font-weight: 500;
}

@media (max-width: 1024px) {
  #sideTabBar li {
    padding: 10px 5px;
    font-size: 13px;
  }

  #prevDate,
  #nextDate {
    padding: 0 8px;
  }
}

@media (max-width: 909px) {
  #showDate {
    font-size: 16px;
  }
}

@media (max-width: 838px) {
  #prevDate,
  #nextDate,
  #showDate {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  #wrap-date {
    font-size: 13px;
  }
}

#dashboardBody .dashboard-expanded {
  flex: 0 0 94.666667%;
  max-width: 94.666667%;
  padding-left: 0;
  padding-right: 20px;
}

.col-md-3 .leftbaricon {
  left: 0;
  z-index: 1;
}

.sidebar-listing-scroll {
  background-color: #fff;
  height: 67vh;
  overflow-y: auto;
}

.graph-wrapper {
  height: 84vh;
  overflow: hidden;
}

@media (max-width: 1220px) {
  #dashboardBody .dashboard-expanded {
    flex: 0 0 92.5%;
    max-width: 92.5%;
  }
  .sidebar-listing-scroll {
    height: 70vh;
  }
  .col-md-1.dashboard-sidebar-collapsed-wrap {
    flex: 0 0 5.5%;
    max-width: 5.5%;
  }
  .dashboard-sidebar #nextDate,
  .dashboard-sidebar #prevDate,
  .dashboard-sidebar #showDate {
    font-size: 13px;
  }
  .dashboard-sidebar #nextDate,
  .dashboard-sidebar #prevDate {
    padding: 0 5px;
  }
  .invoices-wrapper {
    height: 67.5vh;
  }
  .dashboard-sidebar .mData {
    font-size: 10px;
  }
  .dashboard-sidebar .card-body {
    padding-left: 10px;
    padding-right: 10px;
  }
  .dashboard-sidebar #sideTabBar li {
    font-size: 10px;
  }
}
